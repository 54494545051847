import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import InnerHero from "../components/Shared/innerHero"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <InnerHero title="404: Not found" />
    <section className="pt-120 pb-120 position-relative">

    </section>
  </Layout>
)

export default NotFoundPage
